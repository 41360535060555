<script lang="ts" setup>
import { computed, HTMLAttributes, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { AlarmType, TemplateField, TemplateFieldType } from '@/types/damage-report'
import {
    templateFieldTypes,
    alarmBooleanTypes,
    alarmTextTypes,
    alarmNumberTypes,
} from '@/utils/type-translations'
import { ElementInputEvent } from '@/types/general'
import { DropdownOption } from '@/types/inputs'

import FieldTranslationModal from '@/components/damage-reports/FieldTranslationModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'

export interface Props extends /** @vue-ignore */ HTMLAttributes {
    field: TemplateField
    draggable: boolean
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits<{
    (e: 'update:field', value: TemplateField): void
    (e: 'remove', value: TemplateField): void
}>()

const { t } = useI18n()

const alarmType = ref<string | null | AlarmType>(props.field.alarmType)
const alarmValue = ref<number>(props.field.alarmValue || 0)
const fieldTranslationModalOpen = ref(false)

const required = computed({
    get: () => props.field.required,
    set: (value: boolean) => {
        emit('update:field', { ...props.field, required: value })
    },
})

const imageRequired = computed({
    get: () => props.field.imageRequired,
    set: (value: boolean) => {
        emit('update:field', { ...props.field, imageRequired: value })
    },
})

const hideNotPossibleToCheck = computed({
    get: () => props.field.hideNotPossibleToCheck,
    set: (value: boolean) => {
        emit('update:field', { ...props.field, hideNotPossibleToCheck: value })
    },
})

const hasAlarmInput = computed(() => {
    return (
        props.field.alarmType !== null &&
        props.field.type !== TemplateFieldType.Boolean &&
        props.field.type !== TemplateFieldType.Text
    )
})

const visibleAlarmTypes = computed(() => {
    let alarmTypes: Partial<Record<string | number | 'null', string>> = {}
    if (props.field.type === TemplateFieldType.Text) {
        alarmTypes = alarmTextTypes
    } else if (props.field.type === TemplateFieldType.Boolean) {
        alarmTypes = alarmBooleanTypes
    } else {
        alarmTypes = alarmNumberTypes
    }
    return Object.keys(alarmTypes).map((key) => {
        return {
            label: t(alarmTypes[key] as unknown as AlarmType),
            value: Number.isNaN(parseFloat(key)) ? key : parseFloat(key),
        }
    })
})
const tankLevelOptions = computed<DropdownOption[]>(() => {
    if (props.field.type !== TemplateFieldType.TankLevel) return []

    return [
        { label: '0', value: 0 },
        { label: '1/4', value: 0.25 },
        { label: '2/4', value: 0.5 },
        { label: '3/4', value: 0.75 },
        { label: '1', value: 1 },
    ]
})

function removeField() {
    emit('remove', { ...props.field })
}

function alarmTypeChanged() {
    let alarmTypeValue = alarmType.value as AlarmType | null
    let alarmValue = props.field.alarmValue

    if (alarmTypeValue === undefined) {
        alarmTypeValue = null
    }

    if (props.field.type === TemplateFieldType.Boolean && alarmTypeValue !== null) {
        alarmTypeValue = AlarmType.Equals
        alarmValue = alarmType.value === 'true' ? 1 : 0
    }

    emit('update:field', { ...props.field, alarmType: alarmTypeValue, alarmValue })
}

function alarmValueChanged(value: string | number) {
    emit('update:field', {
        ...props.field,
        alarmValue: parseFloat(value + ''),
    })
}

function openFieldTranslationModal() {
    fieldTranslationModalOpen.value = true
}

onBeforeMount(() => {
    if (props.field.type === TemplateFieldType.Boolean && props.field.alarmType) {
        alarmType.value = props.field.alarmValue === 1 ? 'true' : 'false'
    }
})
</script>

<template>
    <div
        class="flex h-auto w-[238px] justify-center rounded-lg bg-primary-50 shadow dark:bg-dark-500"
    >
        <FieldTranslationModal v-model="fieldTranslationModalOpen" :field="field" />

        <div class="flex w-full flex-col space-y-6 p-5">
            <div class="flex flex-col">
                <div class="flex items-center">
                    <mdi:drag
                        v-if="props.draggable"
                        class="handle mr-1 cursor-grab text-xl text-dark-700 transition dark:text-white"
                    />

                    <span
                        class="w-full font-semibold uppercase text-primary-400"
                        v-text="props.field.name"
                    />

                    <MyButton
                        v-tooltip="t('translateField')"
                        plain
                        size="micro"
                        type="button"
                        class="!h-4 !p-0 hover:bg-primary-200"
                        @click="openFieldTranslationModal()"
                    >
                        <mdi:translate class="inline-block h-4 text-blue-400" />
                    </MyButton>
                </div>
                <span
                    class="text-xs font-medium text-primary-300"
                    v-text="t(templateFieldTypes[props.field.type])"
                />
            </div>

            <div class="flex flex-col space-y-1.5">
                <MyCheckbox
                    v-model="required"
                    class="flex flex-row-reverse justify-between"
                    name="required"
                    :label="t('required')"
                />
                <MyCheckbox
                    v-model="imageRequired"
                    class="flex flex-row-reverse justify-between"
                    name="imageRequired"
                    :label="t('imageRequired')"
                />
                <div class="flex space-x-1 items-center justify-between">
                    <span class="text-sm" v-text="t('hideNotPossibleToCheck')" />
                    <mdi:information v-tooltip="t('hideNotPossibleToCheckExplanation')" />
                    <MyCheckbox v-model="hideNotPossibleToCheck" name="hideNotPossibleToCheck" />
                </div>
            </div>

            <div class="flex flex-col space-y-1">
                <div>
                    <MySelect
                        v-model="alarmType"
                        name="type"
                        :wrapper-class="
                            hasAlarmInput ? 'rounded-b-none dark:border-b dark:border-b-white' : ''
                        "
                        :label="t('alarm')"
                        :options="visibleAlarmTypes"
                        :placeholder="t('none')"
                        @change="alarmTypeChanged"
                    />
                    <MyInput
                        v-if="hasAlarmInput && field.type == TemplateFieldType.Number"
                        :value="field.alarmValue"
                        class="rounded-t-none border-t-0"
                        name="value"
                        type="number"
                        @input="alarmValueChanged(($event as ElementInputEvent).target.value)"
                    />
                    <MySelect
                        v-if="hasAlarmInput && field.type == TemplateFieldType.TankLevel"
                        v-model="alarmValue"
                        :options="tankLevelOptions"
                        wrapper-class="rounded-t-none dark:border-t dark:border-t-white"
                        @change="alarmValueChanged(($event || 0) as number)"
                    />
                </div>
                <MyButton
                    class="inline-flex pt-1 text-xs font-semibold text-red-600"
                    type="button"
                    plain
                    @click="removeField()"
                    v-text="t('remove')"
                />
            </div>
        </div>
    </div>
</template>
