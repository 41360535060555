<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuid } from 'uuid'

import useForm from '@/hooks/use-form'
import { TemplateFieldType, TemplateField, AlarmType } from '@/types/damage-report'
import { templateFieldTypes } from '@/utils/type-translations'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    modelValue: boolean
}

interface Form {
    id: string
    name: string
    type: TemplateFieldType
    required: boolean
    imageRequired: boolean
    hideNotPossibleToCheck: boolean
    alarmType: AlarmType | null
    alarmValue: number
    translations: Record<string, string>
    order: number
}

const emit = defineEmits<{
    (e: 'save', value: TemplateField): void
    (e: 'update:modelValue', value: boolean): void
}>()
const props = defineProps<Props>()

const { t } = useI18n()

const { data, loading, errors, reset } = useForm<Form>({
    id: uuid(),
    name: '',
    type: TemplateFieldType.Text,
    required: false,
    imageRequired: false,
    hideNotPossibleToCheck: false,
    alarmType: null,
    alarmValue: 0,
    translations: { en: '' },
    order: 0,
})

const fieldTypes = computed(() => {
    return Object.keys(templateFieldTypes).map((key) => {
        return {
            label: t(templateFieldTypes[key as unknown as TemplateFieldType]),
            value: parseInt(key, 10),
        }
    })
})

async function onSubmit() {
    if (data.type === null) return

    data.translations.en = data.name
    emit('save', { ...data })
    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) {
            reset({ id: uuid() })
            return
        }
    },
)
</script>

<template>
    <MyModal
        :value="props.modelValue"
        back-button
        @back-button-clicked="emit('update:modelValue', false)"
        @close="emit('update:modelValue', false)"
    >
        <template #title>{{ t('addField') }}</template>

        <LoaderWrapper :visible="loading" />

        <MyForm class="space-y-4" :errors="errors" @submit.prevent="onSubmit()">
            <MyInput
                v-model="data.name"
                name="name"
                autofocus
                :label="t('fieldName')"
                :placeholder="t('name')"
                required
            />

            <MySelect
                v-model="data.type"
                name="type"
                :label="t('fieldType')"
                :options="fieldTypes"
                :placeholder="t('none')"
            />

            <div class="flex justify-end">
                <MyButton :disabled="loading" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
